import React from "react";
import dynamic from "next/dynamic";
import { inject, observer } from "mobx-react";
import Router, { useRouter } from "next/router";
import { NextPage, NextPageContext } from "next";
import { IAuthStore } from "src/stores/AuthStore";
import { IStore } from "src/stores/Store";
import { getAccountOtpRegisterPath } from "src/pages/account/otp/register";
import { makeQueryParams, parseQuery } from "src/utils/uri";
import OTPGuideForm from "src/components/Form/OTPGuideForm";
import { getAccountLoginPath } from "src/utils/common/pathHandlers";

type Params = {
  redirect?: string;
};

type Inject = {
  authStore: IAuthStore;
};

type Props = Inject;

const AccountLayout = dynamic(
  () => import("src/components/Layout/AccountLayout"),
  { ssr: false },
);

export const getAccountOtpGuidePath = (params: Params) =>
  `/account/otp/guide${makeQueryParams(params)}`;

const AccountOtpGuide: NextPage<Props> = inject(
  ({ store }: { store: IStore }): Inject => ({
    authStore: store.authStore,
  }),
)(
  observer((props: Props) => {
    const router = useRouter();
    const params = (router.query ?? {}) as Params;

    const next = () => {
      const { password } = props.authStore.user;

      if (password) {
        router.push(getAccountOtpRegisterPath(params));
        return;
      }
      router.push(getAccountLoginPath(params));
    };

    const { otp } = props.authStore;
    const email = props.authStore.user.email;
    const otpUrl = otp?.url
      ? otp?.url.startsWith("otpauth")
        ? otp?.url
        : (parseQuery<{ chl?: string }>(otp?.url)?.chl ?? "")
      : "";
    const otpKey = otp?.key ?? "";

    return (
      <AccountLayout>
        <OTPGuideForm
          email={email}
          otpKey={otpKey}
          otpUrl={otpUrl}
          onConfirm={next}
        />
      </AccountLayout>
    );
  }),
);

AccountOtpGuide.getInitialProps = async (
  ctx: NextPageContext & { store: IStore },
) => {
  const isServer = typeof window === "undefined";
  const { authStore } = ctx?.store;
  const redirectUrl = (url: string) => {
    if (!isServer) {
      Router.push(url);
      return;
    }
    ctx?.res?.writeHead?.(302, {
      Location: url,
    });
    ctx?.res?.end?.();
  };
  if (!Boolean(authStore.isLogin) || !Boolean(authStore.otp)) {
    redirectUrl("/");
  }
  return {} as Props;
};

export default AccountOtpGuide;
